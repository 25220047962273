import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'
import { Row, Col, Container } from '@kogk/common'
import { graphql, useStaticQuery } from 'gatsby'

const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 29
      },
      {
        weight: 0.2
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 18
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36
      },
      {
        color: '#333333'
      },
      {
        lightness: 40
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 17
      },
      {
        weight: 1.2
      }
    ]
  }
]

export const query = graphql`
  query {
    allPrismicVerslun(
      filter: { data: { fyrirtaeki: { eq: "Lyf og Heilsa" } } }
    ) {
      nodes {
        data {
          fyrirtaeki
          hnit {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default ({ lat = '64.130508', lon = '-21.894254999999998' }) => {
  const hnitData = useStaticQuery(query)
  const hnit = hnitData.allPrismicVerslun.nodes.map(
    hnitItem => hnitItem.data.hnit
  )

  return (
    <Container fluid>
      <Row>
        <Col col={12} className='px-0'>
          <MapWithAMarker
            googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyCrTDOqZTFCw_5HntCcTpozu-Bm38-fNso&v=3.exp&libraries=geometry,drawing,places'
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `522px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            markers={hnit}
            initial={{
              lon: lon,
              lat: lat
            }}
          />
        </Col>
      </Row>
    </Container>
  )
}

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        defaultOptions={{
          styles: mapStyles
        }}
        defaultZoom={13}
        defaultCenter={{
          lat: parseFloat(props.initial.lat),
          lng: parseFloat(props.initial.lon)
        }}
      >
        {props.markers &&
          props.markers.map((marker, key) => {
            return (
              <Marker
                key={key}
                position={{ lat: marker.latitude, lng: marker.longitude }}
              />
            )
          })}
      </GoogleMap>
    )
  })
)
